/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useContext, useState, useEffect } from 'react';
import Form, { useForm } from 'components/molecules/Form';
import Field from 'components/molecules/Field';
import Toast from 'components/molecules/Toast';
import Button from 'components/atoms/Button';
// eslint-disable-next-line no-unused-vars
import { css } from 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import adminService from 'services/adminService';
import Grid from '../../atoms/Grid';
import Select from 'components/atoms/Select';
import { getDateObject } from 'helpers/common';
import { format } from 'date-fns';

const AttendenceForm = ({ onClose }) => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [studentList, setStudentList] = useState({});
  const [sessionDate, setSessionDate] = useState('');
  const [expiryDate, setExpiryDate] = useState('');

  const [form] = useForm();
  const { refetch } = useContext(AuthContext);
  useEffect(() => {
    setLoading(true);
    adminService
      .getStudentList()
      .then((res) => {
        setStudentList(res?.studentList);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  }, []);

  const onDownloadPdf = async (id, sheet, type) => {
    try {
      const res = await adminService.downloadPdf({ id, type });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(new Blob([res]));
      a.download = `Attendence-Sheet/${format(
        getDateObject(sheet.session_date),
        'yyyy-MM-dd'
      )}.pdf`;
      a.click();
      setLoading(false);
      document.body.appendChild(a);
      document.body.removeChild(a);
      refetch();
      setLoading(false);
      onClose();
      Toast({
        message: 'Attendence Created Successfully and PDF form Downloaded',
        type: 'success',
      });
    } catch (ex) {
      refetch();
      setLoading(false);
      onClose();
      Toast({
        type: 'error',
        message:
          'Attendence Created but failed to download pdf kindly download it manually',
      });
    }
  };

  const handleSubmit = async (e) => {
    const payload = {
      ...e,
      students: e.students.map((e) => e.label),
      session_date: format(new Date(sessionDate), 'yyyy-MM-dd'),
      inst_expiry_date: expiryDate
        ? format(new Date(expiryDate), 'yyyy-MM-dd')
        : null,
    };
    setLoading(true);
    adminService
      .createAttendenceSheet(payload)
      .then((res) => {
        if (res.error) {
          setLoading(false);
          Toast({
            type: 'error',
            message: res?.message,
          });
        } else {
          onDownloadPdf(
            res?.newAttendence?._id,
            res?.newAttendence,
            'attendence'
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        Toast({
          type: 'error',
          message: err?.message,
        });
      });
  };
  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
      onTouched={(e) => {
        setState((_) => ({ ..._, ...e }));
      }}
      onError={() => {
        Toast({
          type: 'error',
          message: 'Please fill all the required fields',
        });
      }}
    >
      <Form.Item
        name='students'
        label='Select Students'
        rules={[
          { required: true, message: 'Select atleast one student' },
          {
            transform: (value) => !value?.length,
            message: 'Select at least one student',
          },
          {
            transform: (value) => value?.length > 15,
            message: 'Maximum Student limit is 15',
          },
        ]}
      >
        <Select
          sm
          options={studentList}
          isSearchable
          loading={loading}
          isMulti
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
        />
      </Form.Item>
      <Grid xs={1} lg={3} colGap={20}>
        <Form.Item
          type='text'
          label='Course #'
          name='course_number'
          placeholder='Course #'
          rules={[
            { required: true, message: 'Course # is required' },
            {
              pattern: /^[a-zA-Z0-9\s`',.]*$/,
              message: 'Enter a valid Course #',
            },
            {
              pattern: /^[a-zA-Z0-9\s`!@#$%^&*()_+-=:";'<>,.]{1,}$/,
              message: 'Course # must be minimum 1 characters',
            },
            {
              pattern: /^[a-zA-Z0-9\s`!@#$%^&*()_+-=:";'<>,.]{1,30}$/,
              message: 'Course # must be maximum 1 characters',
            },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          type='text'
          label='Session #'
          name='session_number'
          placeholder='Session #'
          rules={[
            { required: true, message: 'Session # is required' },
            {
              pattern: /^[a-zA-Z0-9\s`',.]*$/,
              message: 'Enter a valid Session #',
            },
            {
              pattern: /^[a-zA-Z0-9\s`!@#$%^&*()_+-=:";'<>,.]{1,}$/,
              message: 'Session # must be minimum 1 characters',
            },
            {
              pattern: /^[a-zA-Z0-9\s`!@#$%^&*()_+-=:";'<>,.]{1,30}$/,
              message: 'Session # must be maximum 30 characters',
            },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          sm
          name='session_date'
          label='Session date'
          selected={sessionDate ? new Date(sessionDate) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              session_date: date?.target?.value ? date.target.value : '',
            });
            if (date.target.value === null) {
              setSessionDate('');
            } else {
              setSessionDate(date.target.value);
            }
          }}
          isClearable
          prefix={<i className='material-icons-outlined'>date_range</i>}
          placeholderText='Session date'
          type='datepicker'
          rules={[{ required: true, message: 'Session is required' }]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          type='text'
          label='Start Time'
          name='session_start_time'
          placeholder='Start Time'
          rules={[
            {
              pattern: /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
              message: 'Enter a valid time value i.e 06:00, 00:59, 20:15',
            },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          type='text'
          label='End Time'
          name='session_end_time'
          placeholder='End Time'
          rules={[
            {
              pattern: /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
              message: 'Enter a valid time value i.e 06:00, 00:59, 20:15',
            },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          type='text'
          label='Inst: Name'
          name='inst_name'
          placeholder='Instructor Name'
          rules={[
            { required: true, message: 'Instructor Name is required' },
            {
              pattern: /^[a-zA-Z0-9\s`',.]*$/,
              message: 'Enter a valid Instructor Name',
            },
            {
              pattern: /^[a-zA-Z0-9\s`!@#$%^&*()_+-=:";'<>,.]{3,}$/,
              message: 'Instructor Name must be minimum 3 characters',
            },
            {
              pattern: /^[a-zA-Z0-9\s`!@#$%^&*()_+-=:";'<>,.]{3,30}$/,
              message: 'Instructor Name must be maximum 30 characters',
            },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          type='text'
          label='Instr License #'
          name='instr_driving_lisence'
          placeholder='Driving License #'
          rules={[
            { required: true, message: 'Driving License # is required' },
            {
              pattern: /^.{0,15}$/,
              message: 'Lisence must be maximum 15 characters',
            },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          sm
          name='inst_expiry_date'
          label='Expiry Date'
          selected={expiryDate ? new Date(expiryDate) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              inst_expiry_date: date?.target?.value ? date.target.value : '',
            });
            if (date.target.value === null) {
              setExpiryDate('');
            } else {
              setExpiryDate(date.target.value);
            }
          }}
          isClearable
          prefix={<i className='material-icons-outlined'>date_range</i>}
          placeholderText='Expiry date'
          type='datepicker'
        >
          <Field />
        </Form.Item>
      </Grid>

      <Button
        loading={loading}
        type='primary'
        htmlType='submit'
        rounded
        sm
        width={155}
        css={`
          margin: 0 auto 1.25rem;
          @media (min-width: 768px) {
            margin-bottom: 2.125rem;
          }
        `}
      >
        Submit
      </Button>
    </Form>
  );
};

export default AttendenceForm;
