/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useContext } from 'react';
import Form, { useForm } from 'components/molecules/Form';
import Field from 'components/molecules/Field';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import adminService from 'services/adminService';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';

import Grid from '../../atoms/Grid';
import Select from 'components/atoms/Select';

const InstructorForm = ({ onClose, instructor }) => {
  const [state, setState] = useState({});
  const [expiryDate, setExpiryDate] = useState('');

  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const { refetch } = useContext(AuthContext);

  useEffect(() => {
    if (instructor) {
      form.setFieldsValue({
        driving_lisence: instructor.driving_lisence,
        expiry_date: new Date(instructor.expiry_date).toISOString().split('T')[0],
        instructor: instructor.instructor.map(e => ({ label: e, value: e })),
        instructor_name: instructor.instructor_name
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    try {
      if (instructor) {
        const payload = {
          driving_lisence: e.driving_lisence,
          expiry_date: e.expiry_date,
          instructor: e.instructor.map(e => e.value),
          instructor_name: e.instructor_name
        }
        const response = await adminService.editInstructor(instructor._id, payload)
        if (response.success) {
          Toast({
            type: 'success',
            message: response?.message,
          });
        } else {
          Toast({
            type: 'error',
            message: response?.message,
          });
        }
        refetch()
        onClose()
      } else {
        setLoading(true)
        const payload = {
          driving_lisence: e.driving_lisence,
          expiry_date: e.expiry_date,
          instructor: e.instructor.map(e => e.value),
          instructor_name: e.instructor_name
        }
        const response = await adminService.createInstructor(payload)
        if (response?.success) {
          Toast({
            type: 'success',
            message: response?.message,
          });
        } else {
          Toast({
            type: 'error',
            message: response?.message,
          });
        }
        refetch()
        onClose()
      }
    } catch (err) {
      setLoading(false)
      Toast({
        type: 'error',
        message: err?.message,
      });
    }
  };
  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
      onTouched={(e) => {
        setState((_) => ({ ..._, ...e }));
      }}
      onError={() => {
        Toast({
          type: 'error',
          message: 'Please fill all the required fields',
        });
      }}
    >
      <Grid xs={1} lg={2} colGap={20}>
        <Form.Item
          type='text'
          label='Instructor Name'
          name='instructor_name'
          placeholder='instructor Name'
          rules={[
            { required: true, message: 'Instructor Name is required' },
            { changeRegex: 'capitalize' },
            {
              pattern: /^[a-zA-Z0-9\s`',.]*$/,
              message: 'Enter a valid name',
            },
            {
              pattern: /^[a-zA-Z0-9\s`!@#$%^&*()_+-=:";'<>,.]{3,}$/,
              message: 'Name must be minimum 3 characters',
            },
            {
              pattern: /^[a-zA-Z0-9\s`!@#$%^&*()_+-=:";'<>,.]{3,40}$/,
              message: 'Name must be maximum 40 characters',
            },
          ]}
        >
          <Field />
        </Form.Item>
        <Form.Item
          type='text'
          label='Driving License #'
          name='driving_lisence'
          placeholder='A0000-00000-00000'
          rules={[
            { required: true, message: 'Driving License # is required' },

            {
              pattern: /^[0-9]{3,}$/,
              message: 'Invalid Licence Number',
            },
            {
              pattern: /^[0-9]{3,25}$/,
              message: 'Maximum 25 characters are allowed',
            },
            // { changeRegex: 'licence' },
          ]}
        >
          <Field />
        </Form.Item>
      </Grid>
      <Grid xs={1} lg={2} colGap={20}>
        <Form.Item
          sm
          name='expiry_date'
          label='License Expiry Date'
          selected={expiryDate ? new Date(expiryDate) : new Date()}
          onChange={(date) => {
            form.setFieldsValue({
              expiry_date: date?.target?.value ? date.target.value : '',
            });
            if (date.target.value === null) {
              setExpiryDate('');
            } else {
              setExpiryDate(date.target.value);
            }
          }}
          rules={[
            { required: true, message: 'Please Select Licence Expiry Date' },
          ]}
          isClearable
          prefix={<i className='material-icons-outlined'>date_range</i>}
          placeholderText='Select Date'
          type='datepicker'
        >
          <Field />
        </Form.Item>
        <Form.Item
          name="instructor"
          label='Instructor Type'
          rules={[{ required: true, message: 'Instructor is required' }]}
        >
          <Select
            sm
            options={[{ label: 'In-Class', value: 'In-Class' }, { label: 'In-Car', value: 'In-Car' }]}
            isSearchable
            isMulti
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
          />
        </Form.Item>
      </Grid>
      <Button
        loading={loading}
        type='primary'
        htmlType='submit'
        rounded
        sm
        width={155}
        css={`
          margin: 0 auto 1.25rem;
          @media (min-width: 768px) {
            margin-bottom: 2.125rem;
          }
        `}
      >
        Submit
      </Button>
    </Form>
  );
};

export default InstructorForm;
